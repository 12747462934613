import './styles/main.scss';
import './scripts/main';
import * as CookieConsent from "vanilla-cookieconsent";
import { gtag } from "ga-gtag";

document.getElementById('js-mobile-menu').addEventListener('change', function () {
    const mobileClass = '.r-mobile-aside';
    const mobileAside = document.querySelector(mobileClass);
    mobileAside.classList.toggle('r-mobile-aside--show');
});

document.querySelector('.js-toggle-lang-menu').addEventListener('click', function () {
    const menuClass = '.lang-menu__items';
    const langMenu = document.querySelector(menuClass);
    langMenu.classList.toggle('lang-menu__items--open');
});

const productPackagingVariationsSelect = document.getElementById('productPackagingVariations');
if (productPackagingVariationsSelect) {
    productPackagingVariationsSelect.addEventListener('change', function() {
        window.location.href = window.location.origin + this.value;
    });
}

const menuItems = document.querySelectorAll('.main-menu__item--have-submenu');
menuItems.forEach(item => {
    item.addEventListener('mouseenter', function () {
        const submenu = this.querySelector('.main-menu__submenu');
        if (submenu) {
            submenu.classList.add('main-menu__submenu--show');
        }
    });

    item.addEventListener('mouseleave', function () {
        const submenu = this.querySelector('.main-menu__submenu');
        if (submenu) {
            submenu.classList.remove('main-menu__submenu--show');
        }
    });
});

const submenuItems = document.querySelectorAll('.mobile-main-menu__item--have-submenu');
submenuItems.forEach(item => {
    item.addEventListener('click', function () {
        const submenu = this.querySelector('.mobile-main-menu__submenu');
        if (submenu) {
            submenu.classList.add('mobile-main-menu__submenu--show');
        }
    });

    item.addEventListener('mouseleave', function () {
        const submenu = this.querySelector('.mobile-main-menu__submenu');
        if (submenu) {
            submenu.classList.remove('mobile-main-menu__submenu--show');
        }
    });
});

document.querySelectorAll('.js-add-to-cart').forEach(function (element) {
    element.addEventListener('click', function (e) {
        e.preventDefault();

        const productId = this.getAttribute('data-id');
        if (!productId) {
            return; // Exit if data-id attribute is not present
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${window.location.origin}/add-product`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                location.reload();
            }
        };

        xhr.send('id=' + encodeURIComponent(productId));
    });
});

if (document.getElementById('checkout_haveR1')) {
    document.getElementById('checkout_haveR1').addEventListener('change', function () {
        const companyId = document.getElementById('checkout_company');
        const companyOibId = document.getElementById('checkout_companyOib');

        if (companyId && companyOibId) {
            companyId.classList.toggle('s-form-group__input--hide');
            companyOibId.classList.toggle('s-form-group__input--hide');
            companyId.focus();
        }
    });
}

document.querySelectorAll('.js-minus, .js-plus').forEach(function (element) {
    element.addEventListener('click', function () {
        document.getElementById('cart_save').click();
    });
});

document.querySelectorAll('.js-quick-cart').forEach(function (element) {
    element.addEventListener('click', function () {
        document.querySelector('.quick-cart').classList.toggle('quick-cart--open');
    });
});



const body = document.body;
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
let lastScroll = 0;

window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
        body.classList.remove(scrollUp);
        return;
    }

    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
    } else if (
        currentScroll < lastScroll &&
        body.classList.contains(scrollDown)
    ) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
    }
    lastScroll = currentScroll;
});


CookieConsent.run({
    disablePageInteraction: true,
    cookie: {
        name: 'cc_cookie',
        domain: location.hostname,
    },
    guiOptions: {
        consentModal: {
            layout: 'inline',
            position: 'bottom right',
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: 'box',
            equalWeightButtons: true,
            flipButtons: false
        }
    },

    onConsent: ({cookie}) => {
        if (cookie.categories.length === 3 &&
            cookie.categories.includes('necessary') &&
            cookie.categories.includes('analytics') &&
            cookie.categories.includes('ads')) {
            gtag('consent', 'update', {
                "security_storage": "granted",
                "functionality_storage": "granted",
                "analytics_storage": "granted",
                "personalization_storage": "granted",
                "ad_storage": "granted",
                "ad_user_data": "granted",
                "ad_personalization": "granted",
            });
        } else if(cookie.categories.length === 2 &&
            cookie.categories.includes('necessary') &&
            cookie.categories.includes('analytics')) {
            gtag('consent', 'update', {
                "security_storage": "granted",
                "functionality_storage": "granted",
                "analytics_storage": "granted",
                "personalization_storage": "granted",
            });
        } else if(cookie.categories.length === 2 &&
            cookie.categories.includes('necessary') &&
            cookie.categories.includes('ads')) {
            gtag('consent', 'update', {
                "security_storage": "granted",
                "functionality_storage": "granted",
                "analytics_storage": "granted",
                "ad_storage": "granted",
                "ad_user_data": "granted",
                "ad_personalization": "granted",
            });
        } else if(cookie.categories.length === 1 &&
            cookie.categories.includes('necessary')) {
            gtag('consent', 'update', {
                "security_storage": "granted",
                "functionality_storage": "granted",
                "analytics_storage": "granted",
            });
        }
    },

    onModalReady: ({modalName}) => {
        gtag('consent', 'default', {
            "security_storage": "denied",
            "functionality_storage": "denied",
            "analytics_storage": "denied",
            "personalization_storage": "denied",
            "ad_storage": "denied",
            "ad_user_data": "denied",
            "ad_personalization": "denied",
        });
    },
    categories: {
        necessary: {
            enabled: true,
            readOnly: true
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^_ga/,
                    },
                    {
                        name: '_gid',
                    }
                ]
            },

            services: {
                ga: {
                    label: 'Google Analytics',
                    onAccept: () => {},
                    onReject: () => {}
                },
            }
        },
        ads: {}
    },

    language: {
        default: 'en',
        translations: {
            en: {
                consentModal: {
                    title: 'We use cookies',
                    description: `This site uses cookies. Some of these cookies are essential, while others help us improve your experience by providing insight into how the site is being used. For more detailed information about the cookies we use, see the page <a href="/privacy-policy" target="_blank">Privacy policy.</a>`,
                    acceptAllBtn: 'Accept everything',
                    acceptNecessaryBtn: 'Throw it all away',
                    showPreferencesBtn: 'Managing individual preferences',
                },
                preferencesModal: {
                    title: 'Manage cookie settings',
                    acceptAllBtn: 'Accept everything',
                    acceptNecessaryBtn: 'Throw it all away',
                    savePreferencesBtn: 'Accept current selection',
                    closeIconLabel: 'Close the modal',
                    serviceCounterLabel: 'Service|Services',
                    sections: [
                        {
                            title: 'Your choice of privacy',
                            description: `In this panel, you can express some preferences related to the processing of your personal data. You can review and change your expressed choices at any time by revisiting this panel via the link provided. To refuse your consent to certain processing activities described below, turn off the toggles or use the "Reject all" button and confirm that you want to save your selections.`,
                        },
                        {
                            title: 'Strictly necessary',
                            description: 'These cookies are necessary for the proper functioning of the website and cannot be disabled.',
                            linkedCategory: 'necessary'
                        },
                        {
                            title: 'Performance and analytics',
                            description: 'These cookies collect information about how you use our website. All data is anonymized and cannot be used for your identification.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                caption: 'Cookie table',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Description'
                                },
                                body: [
                                    {
                                        name: '_ga',
                                        domain: location.hostname,
                                        desc: '',
                                    },
                                    {
                                        name: '_gid',
                                        domain: location.hostname,
                                        desc: '',
                                    }
                                ]
                            }
                        },
                        {
                            title: 'Targeting and advertising',
                            description: 'These cookies are used to make advertising messages more relevant to you and your interests. The intent is to display ads that are relevant and attractive to the individual user, and thus more valuable to publishers and third-party advertisers.',
                            linkedCategory: 'ads',
                        },
                        {
                            title: 'More information',
                            description: `For any queries regarding my cookie policy and your choices, please <a href="/contact">contact us</a>`
                        }
                    ]
                }
            }
        }
    }
});
